import packageJson from './../../package.json';
import { EnvironmentConfigModel } from './environment-config.model';

const envClientLocation = '';
const envApiLocation = '';

const envIdpProvider = '';

const envTenantId = '';
const envIssuer = envIdpProvider + '/' + envTenantId + '/v2.0';
const envOauth2Url = envIdpProvider + '/' + envTenantId + '/oauth2/v2.0';

const envClientId = '';

const envAppLocation = '';
const envAppProduction = 'false';

const envShowDebugInformation = 'false';
const envRequireHttps = 'true';
const strictDiscoveryDocumentValidation = 'false';
const skipIssuerCheck = 'true';
const checkOrigin = 'true';

const clearHashAfterLogin = 'false';
const useSilentRefresh = 'true';

export const environmentConfigDefault: EnvironmentConfigModel = {
  applicationName: 'Fund Distribution Manager',
  applicationVersion: packageJson.version,
  applicationBuild: packageJson.version,

  appLocation: envAppLocation,

  production: envAppProduction,

  baseUrl: envClientLocation,
  apiUrl: envApiLocation,

  // Product IAM v2 Setup Start

  idpProviderUrl: envIdpProvider,
  issuer: envIssuer,

  tenantId: envTenantId,
  clientId: envClientId,

  loginUrl: envOauth2Url + '/authorize',
  logoutUrl: envOauth2Url + '/logout',
  tokenEndpoint: envOauth2Url + '/token',

  postLogoutRedirectUri: envClientLocation,

  redirect: envClientLocation,

  responseType: 'code',
  requestAccessToken: false,

  scope: 'openid profile email',

  showDebugInformation: envShowDebugInformation,
  requireHttps: envRequireHttps,
  strictDiscoveryDocumentValidation: strictDiscoveryDocumentValidation,
  skipIssuerCheck: skipIssuerCheck,
  checkOrigin: checkOrigin,

  clearHashAfterLogin: clearHashAfterLogin,
  useSilentRefresh: useSilentRefresh,

  // Product IAM v2 Setup End

  apiEndPoints: {
    appuser: '/appuser',
    auditTrail: {
      fundDetail: '/fund/audit',
      fundMapping: '/fundIncomeField/audit',
      userAllocation: '/fund/userAllocation/audit'
    },
    data: {
      getTransactionalData: '/sourcedata/transactional',
      getPointInTimeData: '/sourcedata/pointintime',
      getFunds: '/sourcedata/funds',
      getAsOfDate: '/sourcedata/{fileTypeId}/asofdate'
    },
    funds: {
      fund: '/fund',
      userAllocation: '/fund/userallocation',
      fundIncomeMapping: '/fundincomefield',
      fundClasses: '/fundClass'
    },
    fundOperation: {
      fundIncomeCategoryAdjustment: '/fundincomefieldadjustment',
      fundIncomeCategoryAdjustmentgetMapping: '/fundincomefieldadjustment/getadjustment',
      fundTaxOptimizedFieldAdjustment: '/fundtaxoptimizedfieldadjustment',
      fundTaxOptimizedFieldAdjustmentGetAdjustment: '/fundtaxoptimizedfieldadjustment/getadjustment',
      fundDistributionTaxableIncomeFieldAdjustment: '/funddistributiontaxableincomefieldadjustment',
      fundDistributionTaxableGetAdjustment: '/Funddistributiontaxableincomefieldadjustment/getadjustment',
      manualAdjustment: '/manualadjustment',
      openingBalance: '/fundyearendbalance',
      fundCashBasisGLAccounts: '/fundgeneralledgerexclusion',
      generalLedgerIncomeField: '/FundIncomeField/Combined'
    },
    generalledgeraccount: {
      generalledgeraccount: '/generalledgeraccount',
      fieldmapping: '/generalledgeraccount/fieldmapping',
      generalLedgerIncomeField: '/generalledgerincomefield',
      generalledgerincomefieldgetmapping: '/generalledgerincomefield/getmapping',
      generalledgertaxoptimizedfield: '/generalledgertaxoptimizedfield'
    },
    lookupCollection: '/lookup',
    miscellaneous: {
      viewData: '/sourcedata/miscellaneous',
      delete: '/upload/miscellaneous/file/{fileId}/deletemiscfile',
      getFilesList: '/upload/miscellaneous/files',
      getFundOnboarding: '/sourcedata/fundonboarding',
      getCDSFile: '/sourcedata/cdsfactor',
      getManualAdjustments: '/sourcedata/manualadjustment/{fundId}'
    },
    scenarios: {
      getScenario: '/scenario',
      getScenarioDrillDownReport: '/scenario/resultincomefield',
      runScenarioSynch: '/scenario/createandrunscenario',
      createOfflineScenario: '/createofflinescenario',
      exportExcel: '/report',
      scenarioAEAIReport: '/report/data/aeai',
      taxFactorTaxOptimizedReport: '/taxfactor',
      downloadErrorFile: '/scenario/export/error',
      downloadWarningFile: '/scenario/export/warning/{scenarioId}'
    },
    upload: {
      getContainerList: '/upload/containers',
      getContainerInfo: '/upload/container',
      createContainer: '/upload/container',
      addSelectedContainerFiletypes: '/upload/container/filetype',
      deleteContainer: '/upload/container',
      markContainerActive: '/upload/container/{containerId}/activate',
      uploadFile: '/upload/container/{containerId}/file/{fileTypeId}',
      uploadMiscellaneousFile: '/upload/{fileTypeId}',
      deleteFile: '/upload/container/{containerId}/containerfile/{containerFileId}/delete',
      deleteContainerFile: '/upload/container/{containerId}/containerfile/{containerFileId}/delete',
      deletePointInTimeFile: '/upload/container/{containerId}/file/{fileId}/deletepitfile',
      downloadFile: '/upload/container/file',
      downloadErrorFile: '/upload/file/{fileId}/error',
      startProcessingAsynch: '/upload/container/{containerId}/processcontainerfiles',
      startProcessing: '/upload/container/{containerId}/processcontainerfilessync',
      refreshStatus: '/upload/container/{containerId}/status',
      asOfDate: '/upload/container/asofdate',
      getAllAsOfDate: '/upload/asofdate'
    }
  },

  logger: {
    logResponse: true,
    logMessage: true,
    logException: true,
    logResponseSize: 10
  },

  store: {
    trackHistory: false,
    logChanges: true
  },

  grid: {
    pageSize: 10,
    pageable: {
      buttonCount: 5,
      info: true,
      type: 'numeric',
      pageSizes: [10, 25, 50, 100, 500, 1000, 10000],
      previousNext: true
    },
    resizable: true,
    sortable: true,
    scrollable: 'scrollable',
    filterable: false,
    groupable: false,
    columnMenu: { filter: false, columnChooser: false }
  }
};
