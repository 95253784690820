import { EnvironmentConfigModel } from './environment-config.model';
import { environmentConfigDefault } from './environment-config-default';

// 13 variables will be override by build pipeline variables
const envClientLocation = '#{APP_ClientLocation}#';
const envApiLocation = '#{APP_ApiLocation}#';

const envIdpProvider = '#{APP_IdpProvider}#';

const envTenantId = '#{APP_TenantId}#';

const envIssuer = envIdpProvider + '/' + envTenantId + '/v2.0';
const envOauth2Url = envIdpProvider + '/' + envTenantId + '/oauth2/v2.0';

const envClientId = '#{APP_ClientId}#';

const envAppLocation = '#{APP_Location}#';
const envAppProduction = '#{APP_Production}#';

const envShowDebugInformation = '#{APP_ShowDebugInformation}#';
const envRequireHttps = '#{APP_RequireHttps}#';

const envStrictDiscoveryDocumentValidation = '#{APP_StrictDiscoveryDocumentValidation}#';
const envSkipIssuerCheck = '#{APP_SkipIssuerCheck}#';
const envClearHashAfterLogin = '#{APP_ClearHashAfterLogin}#';
const envUseSilentRefresh = '#{APP_UseSilentRefresh}#';

export const environment: EnvironmentConfigModel = {
  ...environmentConfigDefault,

  appLocation: envAppLocation,

  production: envAppProduction,

  baseUrl: envClientLocation,
  apiUrl: envApiLocation,

  // Product IAM v2 Setup Start

  idpProviderUrl: envIdpProvider,
  issuer: envIssuer,

  tenantId: envTenantId,
  clientId: envClientId,

  loginUrl: envOauth2Url + '/authorize',
  logoutUrl: envOauth2Url + '/logout',
  tokenEndpoint: envOauth2Url + '/token',

  postLogoutRedirectUri: envClientLocation,

  redirect: envClientLocation,

  showDebugInformation: envShowDebugInformation,
  requireHttps: envRequireHttps,

  strictDiscoveryDocumentValidation: envStrictDiscoveryDocumentValidation,
  skipIssuerCheck: envSkipIssuerCheck,

  clearHashAfterLogin: envClearHashAfterLogin,
  useSilentRefresh: envUseSilentRefresh

  // Product IAM v2 Setup End
};
