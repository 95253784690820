import { Injectable } from '@angular/core';

import { environment } from '@app-environments/environment';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {
  private messageFormatting = 'background: #222; color: #22992E; font-size: 12px;';
  private warningFormatting = 'background: #222; color:  #FFB600; font-size: 12px;';
  private errorFormatting = 'background: #222; color: #FF0000; font-size: 14px;';

  private responseFormatting = 'background: #222; color: #6699FF; font-size: 12px;';

  appEnvironment = environment;

  constructor(private utilService: UtilService) {}

  // NOTE: Mandatory for logging as we don't know type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private logData(messageType: string, item: any, message = '', formatting = ''): void {
    if (message) {
      console.log(`%c${messageType} - ${message}`, formatting);
    }
    if (item) {
      console.log(item);
    }
  }
  // NOTE: Mandatory for logging as we don't know type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  logMessage(message: string, item: any = null, formatting: string | null = null): void {
    if (this.appEnvironment.logger.logMessage) {
      const timeStamp = this.getTimestamp();
      this.logData(`Message ${timeStamp}`, item, message, formatting ? formatting : this.messageFormatting);
    }
  }

  // NOTE: Mandatory for logging as we don't know type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  logException(error: any, message = ''): void {
    if (this.appEnvironment.logger.logException) {
      const timeStamp = this.getTimestamp();
      this.logData(`Exception ${timeStamp}`, error, message ?? 'Error', this.errorFormatting);
    }
  }

  // NOTE: Mandatory for logging as we don't know type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  logResponse(response: any): void {
    if (this.appEnvironment.logger.logResponse && response) {
      const timeStamp = this.getTimestamp();

      if (Array.isArray(response) && response.length > this.appEnvironment.logger.logResponseSize) {
        this.logMessage(
          `Logging shorter version of response - size ${this.appEnvironment.logger.logResponseSize}`,
          null,
          this.warningFormatting
        );

        this.logData(`Response ${timeStamp} - `, response.slice(0, this.appEnvironment.logger.logResponseSize));
      } else {
        this.logData(`Response ${timeStamp}`, response, 'Http Response', this.responseFormatting);
      }
    }
  }

  // NOTE: Mandatory for logging as we don't know type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  logItem(response: any): void {
    if (this.appEnvironment.logger.logResponse && response) {
      const timeStamp = this.getTimestamp();
      this.logData(`Item ${timeStamp} - `, response);
    }
  }

  private getTimestamp(): string {
    const now = this.utilService.getCurrentDate();
    // single line to show in that format in browser console

    return `${now.getHours()}:${this.addLeadingZero(now.getMinutes())}:${this.addLeadingZero(
      now.getSeconds()
    )}.${this.addLeadingZero(now.getMilliseconds())}`;
  }

  private addLeadingZero(value: number): string {
    return value < 10 ? `0${value.toString()}` : value.toString();
  }
}
