import { DecimalPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { ValidatorFn } from '@angular/forms';

import isEmpty from 'lodash-es/isEmpty';

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  //#region Lodash wrappers

  // NOTE Do not remove this line as the request can be any type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  isEmpty(value?: any): boolean {
    return isEmpty(value);
  }

  // NOTE Do not remove this line as the request can be any type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  extractActiveDescriptions(items: any[], field: string): string[] {
    return items.filter(item => item.active).map(item => item[field] as string);
  }

  generateDateString(inputDate: Date, dateSeparator = '-'): string {
    if (!inputDate) {
      return '';
    }
    const dayOption = {
      day: '2-digit'
    };
    const monthOption = {
      month: '2-digit'
    };
    return (
      inputDate.toLocaleDateString('en-US', dayOption as Intl.DateTimeFormatOptions) +
      dateSeparator +
      inputDate.toLocaleDateString('en-US', monthOption as Intl.DateTimeFormatOptions) +
      dateSeparator +
      inputDate.getFullYear()
    );
  }

  truncateTextIfExceedsCharCount(text: string, maxCount: number): string {
    if (text && maxCount) {
      if (text.length <= maxCount) {
        return text;
      } else {
        return text.slice(0, maxCount) + '...';
      }
    }
    return '';
  }

  getCurrentDate(): Date {
    return new Date();
  }

  getCurrentYear(): number {
    return this.getCurrentDate().getFullYear();
  }

  formatDate(date: Date | null): string {
    if (date) {
      const utcDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
      return utcDate?.toISOString();
    }
    return '';
  }

  formatPercentage(value: number): number {
    return value ? value * 100 : 0;
  }

  formatNumber(value: number | string): number {
    return Number(value);
  }

  replaceString(sourceValue: string, searchValue: string, replacementValue: number | string): string {
    if (isEmpty(sourceValue)) {
      return sourceValue;
    }
    return sourceValue.replace(searchValue, replacementValue.toString());
  }

  replaceValue(sourceValue: string, searchValue: string, replacementValue: number | string): string {
    if (isEmpty(sourceValue)) {
      return sourceValue;
    }
    return sourceValue.replace(searchValue, replacementValue.toString());
  }

  validateNumberWithDecimal(
    maxDigits: number,
    maxDecimalDigits: number,
    acceptNegative = false,
    acceptZero = false
  ): ValidatorFn {
    return (control: { value: string }) => {
      const value = control.value;

      if (value === null || value === undefined || value === '') {
        return null;
      }

      if (!acceptZero && value === '0') {
        return { invalidFormat: true };
      }

      const regexPattern = acceptNegative
        ? `^-?\\d{1,${maxDigits}}(\\.\\d{1,${maxDecimalDigits}})?$`
        : `^\\d{1,${maxDigits}}(\\.\\d{1,${maxDecimalDigits}})?$`;

      const regex = new RegExp(regexPattern);
      const isValid = regex.test(value);

      return isValid ? null : { invalidFormat: true };
    };
  }

  alphanumericWithDashValidator(): ValidatorFn {
    return (control: { value: string }) => {
      const value = control.value;
      if (!value) {
        return null;
      }
      const regex = /^[a-zA-Z0-9_ !@#$%^&*)(+-:]+(?:[-\s][a-zA-Z0-9]+)*$/;
      const isValid = regex.test(value);
      return isValid ? null : { invalidFormat: true };
    };
  }

  percentageValidator(): ValidatorFn {
    const regex = /^((100(\.0*)?)|(\d{1,2}(\.\d*)?))%?$/;

    return (control: { value: number | string }) => {
      if (control.value) {
        const value = control.value.toString();
        if (!regex.test(value)) {
          return { invalidFormat: true };
        }

        const numericValue = parseFloat(value.replace('%', ''));
        if (numericValue < 0 || numericValue > 100) {
          return { invalidFormat: true };
        }
      }
      return null;
    };
  }

  extractSubstring(text: string | null, key: string, extension?: string): string {
    if (!text || !key) {
      return '';
    }
    let value = text.split(key)[1];

    value = value.split(';')[0].trim();
    value = value.replace(/^["']|["']$/g, '');

    if (extension && value.endsWith(extension)) {
      value = value.replaceAll('"', '').slice(0, -extension.length);
    }

    return value;
  }

  copyText(text: string): string {
    if (text) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          return 'Text copied to clipboard';
        })
        .catch(err => {
          return `Failed to copy text: ${err}`;
        });
    }
    return 'Invalid Text';
  }

  // NOTE Do not remove this line as the request can be any type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sortAlphabetically(list: any[], field: string): any[] {
    return list.sort((a, b) => a[field].localeCompare(b[field]));
  }

  applyDecimalFilter(
    // NOTE Do not remove this line as the request can be any type
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any[],
    column: string,
    value: string,
    decimalPipe: DecimalPipe,
    decimalFormat: string,
    isPercentage: boolean
    // NOTE Do not remove this line as the request can be any type
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): any[] {
    return data.filter(item => {
      const fieldValue = isPercentage ? item[column] * 100 : item[column];
      const formattedValue = decimalPipe.transform(fieldValue, decimalFormat) || '';
      return formattedValue?.includes(value);
    });
  }

  //#endregion
}
