import { HttpClient, HttpResponse } from '@angular/common/http';

import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from '@app-environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpWrapperService {
  private readonly actionUrl: string;

  constructor(private httpWrapperService: HttpClient) {
    this.actionUrl = environment.apiUrl;
  }

  get<T>(url: string): Observable<T> {
    return this.httpWrapperService.get<T>(this.actionUrl + url);
  }

  getBlob(url: string): Observable<HttpResponse<Blob>> {
    return this.httpWrapperService.get(this.actionUrl + url, {
      observe: 'response',
      responseType: 'blob'
    });
  }

  // NOTE Do not remove this line as the request can be any type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  post<T>(url: string, body: any): Observable<T> {
    return this.httpWrapperService.post<T>(this.actionUrl + url, body);
  }

  // NOTE Do not remove this line as the request can be any type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  put<T>(url: string, body: any): Observable<T> {
    return this.httpWrapperService.put<T>(this.actionUrl + url, body);
  }

  delete<T>(url: string, id?: string): Observable<T> {
    const apiUrl = id ? this.actionUrl + url + '/' + id : this.actionUrl + url;
    return this.httpWrapperService.delete<T>(apiUrl);
  }

  patch<T>(url: string, body: string): Observable<T> {
    return this.httpWrapperService.patch<T>(this.actionUrl + url + '/', body);
  }
}
